import React, { useState } from 'react';
import './Navbar.css';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleToggleMenu = (command) => {
    if(command === 'close') return setMenuOpen(false);
    setMenuOpen(!menuOpen);
  };

  const scrollToElement = (id) => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      handleToggleMenu('close'); // Close the menu after clicking
    }
  };

  return (
    <nav>
      <div className="title" onClick={() => scrollToElement('home')}>
        <img className="logo-icon" src="/pictures/logo.png" alt="logo" />
      </div>
      <div className="menu" onClick={handleToggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className={menuOpen ? 'open' : ''}>
        <li onClick={() => scrollToElement('palvelut')}>Palvelut</li>
        <li onClick={() => scrollToElement('galleria')}>Galleria</li>
        <li onClick={() => scrollToElement('hinnasto')}>Hinnasto</li>
        <li onClick={() => scrollToElement('yhteystiedot')}>Yhteystiedot</li>
      </ul>
    </nav>
  );
};

export default Navbar;
