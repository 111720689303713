import React from 'react';
//import { useState, useEffect, useRef } from 'react'
import './Flowchart.css'; // Import your CSS file
import { steps } from './flowchart_steps';

const FlowchartStep = ({ title, description, iconPath }) => (
  <div className="step">
    <h3>{title}</h3>
    {iconPath && <img src={process.env.PUBLIC_URL + iconPath} alt="Step Icon" className="step-icon" />}
    <p>{description}</p>
  </div>
);

const FlowchartArrow = ({ opacity }) => (
  <div className="arrow" style={{ opacity: opacity }}></div>
);

const VerticalFlowchart = () => {
//  const [opacity, setOpacity] = useState(1);
/*   let thresholdRef = useRef(0.2);

//ei toimi
  useEffect(() => {
    const handleScroll = () => {

      // Decrease the opacity on every scroll event
      let newOpacity = opacity; // Adjust the decrement value as needed
      // Check if opacity is below the threshold, and reset it to 1
      if (newOpacity < thresholdRef.current) {
        newOpacity = newOpacity + 0.02;
        console.log('++')
        if (newOpacity <= 1) {
          thresholdRef.current = 1;
        }
      }
      else if (newOpacity >= 1) {
        newOpacity = newOpacity - 0.02;
        if (newOpacity >= 0.2) {
          thresholdRef.current = 0.2;
        }
      }

      setOpacity(newOpacity);
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [opacity]);
 */
  

  return (
    <div className="vertical-flowchart-container">
      <h2>Vuokravälityksen sisältö</h2>
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <FlowchartStep title={step.title} description={step.description} iconPath={step.iconPath} />
          {index < steps.length - 1 && <FlowchartArrow />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default VerticalFlowchart;

