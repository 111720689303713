// src/components/Properties.js
import React from 'react';
import Flowchart from './Flowchart';
import InfoBox from './InfoBox';
import './Service.css'
const Service = ({ id }) => {
  return (
    <div id={id}>
      <InfoBox info="Markkinoiden edullisin vuokravälitys. Välityspalkkioni on 0.8 kertaa kuukauden vuokra." />
      <Flowchart />
      
    </div>
  );
};

export default Service;
