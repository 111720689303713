// src/components/Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>Puhelin : <a href="tel:+0505605801">0505605801</a>  </p>
        <p>Email: <a href="mailto:dan@propermanager.fi">dan@propermanager.fi</a></p>
      </div>
    </footer>
  );
};

export default Footer;
