export const steps = [
    // ... Your existing steps
    { title: 'Aloitus',
        description: 'Välittäjä kuuntelee vuokranantajan ensitoiveita asunnon vuokraukseen liittyen ja auttaa mieltä askarruttavissa kysymyksissä. ',
        iconPath: '/pictures/phone.png' },
    { title: 'Tutustuminen',
        description: 'Välittäjä tutustuu paikan päällä asuntoon ja ottaa edustavat valokuvat asunnosta.',
        iconPath: '/pictures/camera.png' },
    { title: 'Toimeksianto',
        description: 'Vuokranantajan ja välittäjän välinen toimeksiantosopimus allekirjoitetaan. Toimeksiantosopimus määrittää vuokrauspalvelun sisällön. Välittäjä tilaa isännöitsijäntodistuksen, josta vuokranantaja saa oman kopion.',
        iconPath: '/pictures/contract.png' },
    { title: 'Markkinointi',
        description: 'Välittäjä laatii asunnosta vuokrailmoituksen ja julkaisee sen vuokrausportaaleissa. Välittäjä järjestää näyttöjä asunnolla.',
        iconPath: '/pictures/upload.png' },
    { title: 'Haastattelut',
        description: 'Välittäjä haastattelee vuokralaisia sekä tarkastaa luotto- ja muut taustatiedot.',
        iconPath: '/pictures/microphone.png' },
    { title: 'Valinta',
        description: 'Välittäjä esittelee vuokralaisehdokkaat vuokranantajalle. Vuokranantaja valitsee vuokralaisen.',
        iconPath: '/pictures/person.png' },
    { title: 'Vuokrasopimus',
        description: 'Välittäjä laatii vuokrasopimuksen vuokralaisen ja vuokranantajan allekirjoitettavaksi.',
        iconPath: '/pictures/handshake.png' },
    { title: 'Luovutus',
        description: 'Välittäjä luovuttaa asunnon vuokralaiselle kun takuuvuokra on maksettu.',
        iconPath: '/pictures/key.png' },
    { title: 'Lopputarkastus',
        description: 'Vuokranantajan uusiessa toimeksiantosopimuksen Proper Managerin kanssa, sisältyy välitykseen myös asunnon lopputarkastus. ',
        iconPath: '/pictures/plus.png' },
  ];