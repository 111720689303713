// src/components/Contact.js
import React from 'react';
import './Contact.css';

const Contact = ({ id }) => {
  return (
    <div id={id}>
      <h2>Yhteystiedot</h2>
      <p>Proper Manager Oy</p>
      <ul>
        <li>Vastaava hoitaja: Dan Lundström LVV</li>
        <li>Puhelin: 050 5605801</li>
        <li>Sähköposti: dan@propermanager.fi</li>
        <li>Käynti- ja postiosoite: Koriston Rantatie 42 20760 Kaarina</li>
      </ul>
    </div>
  );
};

export default Contact;
