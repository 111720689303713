import React from 'react';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Service from './components/Service';
import Slider from './components/Slider';
import Pricing from './components/Pricing';
import Contact from './components/Contact';
import Footer from './components/Footer';

import './App.css';

function App() {
  return (
    <div id="App">
      <Navbar />
      <div id="content">
      <Home id="home"  />
      <Service id="palvelut" />
      {/* <Gallery id="galleria" /> */}
      </div>
      <Slider id="slider"/>
      <div id="content">
      <Pricing id="hinnasto" />
      <Contact id="yhteystiedot" />
      </div>
      <Footer />
    </div>
  );
}

export default App;
