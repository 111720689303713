import React, { useState } from 'react';
import './Slider.css';

const Slider = () => {
  const imageContext = require.context('../../public/gallery', false, /\.(jpg|jpeg|png)$/);
  const images = imageContext.keys().map(imageContext);

  const ImageSlider = ({ images }) => {
    const [current, setCurrent] = useState(0);
    const length = images.length;
  
    const nextSlide = () => {
      setCurrent(current === length - 1 ? 0 : current + 1);
    };
  
    const prevSlide = () => {
      setCurrent(current === 0 ? length - 1 : current - 1);
    };
  
    if (!Array.isArray(images) || images.length <= 0) {
      return null;
    }
  
    return (
      <section className='slider'>
        <span className='slide-arrow' id="left-arrow" onClick={prevSlide}>{'<'}</span>
        <span className='slide-arrow' id="right-arrow"  onClick={nextSlide}>{'>'}</span>
        {images.map((image, index) => {
          return (
            <div
              className={index === current ? 'slide active' : 'slide'}
              key={index}
            >
              {index === current && (
                <img src={image} alt={`slide-${index}`} className='image' />
              )}
            </div>
          );
        })}
      </section>
    );
  };

  return (
    <div id='galleria'>
      <h1 id='gallery-header'>Kuvia vuokratuista asunnoista</h1>
      <div id='slider-container'>
        <ImageSlider images={images} />
        {/* Other components or content */}
      </div>
      <div id='gallery-footer'>
      </div>
    </div>
  );
};

export default Slider;
