import React from 'react';
import './Home.css';
import InfoBox from './InfoBox';

const Home = ({ id }) => {
  return (
    <div id={id}>
      <div className="info-box-container">
        <img className="bg-img" src={process.env.PUBLIC_URL + '/other_pictures/bg.jpg'} alt="Background" />
        <div className="home-info-box">
          <h1>Proper Manager - Vuokranantajan kumppani</h1>
        </div>
      </div>
      <div className="home-content">
        <div className="about">
          <h1>Minusta</h1>
          <p>
            Olen asuntosijoittaja ja vuokravälittäjä Turun seudulta. 
            Olen toiminut vuokranantajana vuodesta 2017, ja vastannut asuntojen ostoista, saneerauksista sekä vuokrasuhteiden ylläpidosta.
            <br></br>Oma intohimoni asuntosijoittamisesta ajoi minut perustamaan Proper Managerin, joka tarjoaa vuokranantajille kattavan palvelukokonaisuuden.
            Tarjoan asuntojen vuokravälitystä, vuokraamiseen liittyviä oheispalveluita ja asuntojen pintaremontteja.
            Haluan että elämäsi vuokranantajana on mahdollisimman vaivatonta ja helppoa.
            <br></br><br></br>Terveisin Dan Lundström LVV
          </p>
        </div>
        <img className="my-pic" src={process.env.PUBLIC_URL + '/other_pictures/me.jpg'} alt="Me" />
      </div>
    </div>
  );
};

export default Home;

