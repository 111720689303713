import './Pricing.css';

const Pricing = ({ id }) => {
    return (
      <div id={id}>
        <h1>Hinnasto</h1>
        <h3>Vuokravälitys</h3>
        <ul>
            <li>0.8 x kk vuokra + kulu isännöitsijäntodistustuksesta </li>
            <li>Min. veloitus 400€ + isännöitsijäntodistus</li>
        </ul>

        <h4>Palvelun sisältö</h4>
        <ul>
            <li>Asuntoon tutustuminen</li>
            <li>Asunnon valokuvaus</li>
            <li>Isännöitsijäntodistuksen tilaus</li>
            <li>Asunnon markkinointi</li>    
            <li>Asunnon esittelyt</li>
            <li>Vuokrasopimuksen laatiminen</li>
            <li>Luottotietojen tarkistaminen</li>
            <li>Asunnon luovutus</li>
            <li>Asunnon lopputarkastus kun toimeksianto uusitaan</li>
        </ul>
        <h3>Asunnon valokuvaus</h3>
            <ul>
                <li>Alkaen 80€</li>
            </ul>
        <h4>Palvelun sisältö</h4>
        <ul>
            <li>Edustavat sisä- ja ulkokuvat</li>
            <li>Kuvankäsittely </li>
            <li>Asunnon pohjapiirros</li>
            <li>Kuvien vapaa käyttöoikeus</li>
        </ul>
        <p>Muut työt 65€/h tai tarjousperusteisesti.</p>
        <h5>Kaikki hinnat sisältävät arvonlisäveron</h5>
      </div>
    );
  };
  
  export default Pricing;