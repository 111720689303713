import "./InfoBox.css"

const InfoBox = ({ info }) => {
    return (
        <div className="info-box">
            <h1>{info}</h1>
        </div>
    );
    }

export default InfoBox;